import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import SEO from 'components/Seo';

import Features from './main/features';
import Hero from './main/hero';
import Media from './main/media';
import Social from './main/social';

const IndexPage = ({ location }) => (
  <Layout className="">
    <SEO
      description="Your Everyday Dabao App."
      keywords={['kopirun', 'delivery', 'dabao', 'group buy']}
      path={location.pathname}
      title="KopiRun"
    />
    <div className="max-w-7xl mx-auto">
      <Hero />
      <Features />
      <Media />
      <Social />
    </div>
  </Layout>
);

IndexPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default IndexPage;
